<template>
  <div class="contentBox">
    <div class="contentBox__head">{{$t('Target')}}</div>
    <div class="contentBox__body">
      <template v-if="campaign">
        <div class="contentBox__body--title">
          <span>{{ influencers }}</span>
          {{$t('Local influencers availabe')}}
        </div>
        <div class="d-flex align-items-center justify-content-between mb-15">
          <p class="heading m-0">{{$t('Followers')}}</p>
          <span class="range">{{ campaign.followers }}</span>
        </div>
        <!-- Rang slider -->
        <base-slider
          :min="1"
          :max="filters.followers"
          v-model="campaign.followers"
          @change="getEstimates"
        />
        <div class="d-flex align-items-center justify-content-between mb-15">
          <p class="heading m-0">{{$t('Price range')}} / {{$t('post')}}</p>
          <span class="range"
            >{{ campaignPrice[0] }} - {{ campaignPrice[1] }}</span
          >
        </div>
        <!-- MultiRang slider -->
        <base-slider
          :min="1"
          :max="60"
          v-model="campaignPrice"
          @change="updatePrice"
        />
        <!-- rating -->
        <!-- <p class="heading">Rating</p>
        <base-star-rating
          v-model="campaign.rating"
          @change="getEstimates"
        ></base-star-rating> -->
        <!-- rating -->
      </template>
      <!-- Accordions -->
      <div class="accordion" role="tablist">
        <!-- (1 Age and Gender) -->
        <b-card :class="ageVisible ? 'active' : ''" no-body>
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info"
              ><p>{{$t('Age and gender')}}</p>
              {{ campaign.ageStart }}
              -
              {{ campaign.ageEnd }}+ | {{ getGenderSelection() }}</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-1"
            visible
            accordion="my-accordion"
            role="tabpanel"
            v-model="ageVisible"
          >
            <b-card-body>
              <base-radio-group
                wrapper-class="radio-button"
                name="Gender"
                :options="gender"
                :value="campaign.gender"
                v-model="campaign.gender"
                @input="getEstimates"
              ></base-radio-group>
              <!--End  radio buttopns -->
              <!-- Rang slider -->
              <div
                class="d-flex align-items-center justify-content-between mb-15"
              >
                <p class="heading m-0">{{$t('Age')}}</p>
                <span class="range"
                  >{{ campaignAgeRange[0] }} - {{ campaignAgeRange[1] }}</span
                >
              </div>
              <div class="multiSelectSlider customPadding">
                <base-slider
                  :min="1"
                  :max="80"
                  v-model="campaignAgeRange"
                  @change="updateAge"
                />
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- (2 Location) -->
        <b-card
          no-body
          class="location"
          :class="locationVisible ? 'active' : ''"
        >
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info"
              ><p>{{$t('Location')}}</p>
              {{ locationSelected }}</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-2"
            accordion="my-accordion"
            role="tabpanel"
            v-model="locationVisible"
          >
            <b-card-body>
              <!-- dropdown -->
              <base-multi-select
                :list="filters.locations"
                :multiple="true"
                label="city"
                id="id"
                v-model="campaign.location"
                @input="getEstimates"
              ></base-multi-select>
              <!-- <base-select :options="filters.locations" initialValue="Entire Country" /> -->
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- (5 Tags) -->
        <b-card no-body class="tags" :class="tagsVisible ? 'active' : ''">
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="info"
              ><p>{{$t('Interests')}}</p>
              {{ tagsSelected }}</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-5"
            accordion="my-accordion"
            role="tabpanel"
            v-model="tagsVisible"
          >
            <b-card-body>
              <!-- dropdown -->
              <base-multi-select
                :list="filters.tags"
                :multiple="true"
                label="value"
                id="id"
                v-model="campaign.tag"
                @input="getEstimates"
                class="filter-tags"
              ></base-multi-select>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- (5 Tags) -->
        <!-- (3 Interests) -->
        <!-- <b-card
          no-body
          class="interest"
          :class="interestVisible ? 'active' : ''"
        >
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info"
              ><p>Interests</p>
              {{ interestSelection }}
            </b-button>
          </b-card-header>
          <b-collapse
            id="accordion-3"
            accordion="my-accordion"
            role="tabpanel"
            v-model="interestVisible"
          >
            <b-card-body>
              <base-smooth-scrollbar height="200px">
                <base-checkbox-group
                  name="Interests"
                  :options="filters.interests"
                  :value="campaign.interests"
                  v-model="campaign.interests"
                  wrapper-class="stacked-list"
                  @input="getEstimates"
                ></base-checkbox-group>
              </base-smooth-scrollbar>
            </b-card-body>
          </b-collapse>
        </b-card> -->
        <!-- (4 Quality Filters) -->
        <b-card no-body class="filters" :class="qualityVisible ? 'active' : ''">
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info"
              ><p>{{$t('Quality Filters')}}</p></b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-4"
            accordion="my-accordion"
            role="tabpanel"
            v-model="qualityVisible"
          >
            <b-card-body>
              <!-- <div class="title">
                <span>{{ campaign.influencers }}</span
                >Local influencers Available
              </div> -->
              <div class="row">
                <div class="col-md-6">
                  <p>{{$t('Minimum rating for influencers')}}</p>
                  <!-- rating -->
                  <base-star-rating
                    :value="2"
                    @input="getEstimates"
                  ></base-star-rating>
                  <!-- rating -->
                </div>
                <!-- influencers -->
                <div class="col-md-6">
                  <p>Collaborations made by influencers</p>
                  <base-radio-group
                    wrapper-class="radio-button radio-pills"
                    :options="collaborations"
                    @input="getEstimates"
                  ></base-radio-group>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <!-- End Accordions -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";
export default {
  props: {
    campaign: Object,
  },
  data() {
    return {
      influencers: 0,
      ageVisible: false,
      locationVisible: false,
      interestVisible: false,
      qualityVisible: false,
      collaborations: [
        {
          text: "1+",
          value: "1+",
        },
        {
          text: "5+",
          value: "5+",
        },
        {
          text: "10+",
          value: "10+",
        },
      ],
      all: {
        text: "All",
        value: "0",
      },
      gender: [],
      genderSelected: "All",
      locationSelected: this.$i18n.t('Entire Country'),
      interestSelection: "All",
      campaignPrice: [0, 0],
      campaignAgeRange: [0, 0],
      tagsSelected: this.$i18n.t('Entire Interests'),
      tagsVisible: false,
    };
  },
  computed: {
    ...mapState({
      filters: (state) => state.campaign.filters,
      estimates: (state) => state.campaign.estimates,
    }),
  },
  created() {
    this.campaignPrice = [this.campaign.priceStart, this.campaign.priceEnd];
    this.campaignAgeRange = [this.campaign.ageStart, this.campaign.ageEnd];
    this.influencers = this.filters.influencers;
  },
  watch: {
    filters: {
      handler() {
        this.gender = cloneDeep(this.filters.gender);
        this.gender.unshift(this.all);
      },
      deep: true,
      immediate: true,
    },
    estimates: {
      handler() {
        this.influencers = this.estimates.influencers;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getLocationSelection();
    this.getInterestSelection();
    this.getTagSelection();//Tag Listing
  },
  methods: {
    updateAge(age) {
      this.campaign.ageStart = age[0];
      this.campaign.ageEnd = age[1];
      this.getEstimates();
    },
    updatePrice(value) {
      this.campaign.priceStart = value[0];
      this.campaign.priceEnd = value[1];
      this.getEstimates();
    },
    getEstimates() {
      this.getLocationSelection();
      this.getInterestSelection();
      this.getTagSelection();//Tag Listing
      this.campaign.influencers = 0;
      this.$emit("getEstimate");
    },
    getGenderSelection() {
      let obj = this.gender.find((g) => g.value == this.campaign.gender);
      return obj.text;
    },
    getLocationSelection() {
      this.locationSelected = this.$i18n.t('Entire Country');
      if (this.campaign.location.length > 0) {
        this.locationSelected = this.campaign.location
          .map((item) => item.city)
          .toString();
      }
    },
    getInterestSelection() {
      this.interestSelection = "All";
      if (this.campaign.interests.length > 0) {
        const selected = this.filters.interests.filter((int) =>
          this.campaign.interests.includes(int.value)
        );
        this.interestSelection = selected.map((item) => item.text).toString();
      }
    },
    getTagSelection() {
      this.tagsSelected = this.$i18n.t('Entire Interests');
      if (this.campaign.tag.length > 0) {
        this.tagsSelected = this.campaign.tag
          .map((item) => item.value)
          .toString();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .filter-tags {
    .multiselect {
      &__content-wrapper {
        max-height: 250px !important;
        overflow: auto !important;
      }
    }
  }
}
</style>
